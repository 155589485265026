body {
  margin: 0;
  padding: 0;
  font-family: Raleway;
  background: #eee;
  color: #111;

}
body * {
  transition: all 0.300s ease-in-out;
  border-radius: 7px 7px 0px 0px;
}

.container { 
  /* background-color: #fff; */
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

.hero {
  background: url("http://www.cgarchitect.com/content/portfolioitems/2014/01/92409/architectural-visualization-luxury-house-2_large.jpg");
  /* opacity:0.7; */
  background-size: 100%;
  height: 700px;
  background-position: center;
  position: relative;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   border-radius: 7px 7px 0px 0px;
   height: 100%;  
/* overflow: hidden;    */
/* opacity: 1.0; */
-webkit-transition: background 1.5s linear;
-moz-transition: background 1.5s linear;
-o-transition: background 1.5s linear;
-ms-transition: background 1.5s linear;
transition: background 1.5s linear;
/* animation: anim 20s linear infinite; */
}
@keyframes anim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100%{
    transform: scale(1);
  }
}

.landingPageh2 {
  font-size: 2rem;
  font-weight: 1000;


}

.heroh2{
  font-size: 2rem;
  font-weight: 1000;
}



.hero section.head {
  position: relative;
  z-index: 2;
  display: grid;
  /* width: 70%; */
  /* grid-template-columns: repeat(2,1fr); */
  margin: 0 auto;
  padding-top: 10%;
  align-items: center;
  
}

.hero section.head  {
  color: #222;
/* background-color: rgb(241, 224, 224); */
/* Fallback color */
background-color: rgba(255, 255, 255, 0.582);
text-align: center;
/* Black w/opacity/see-through */
}


/* .hero div.head h1 {
  text-align: center;
  font-size: 5rem;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 0px;
  margin: 0;
  letter-spacing: 4px;
  border-right: 2px solid #111;
} */

.hero section.head p {
  /* padding-left: 30px; */
  color:rgb(106, 81, 49);
  font-size: 1.5rem;
  font-weight: 1000;
  /* text-align: center; */
}

.hero__btn_div {
  margin: 0px auto;
}

.hero__btn {
  
 background: rgba(62, 62, 65, 0.838);
 display: block;
 color: #fff;
 font-weight: bold;
 width: 40%;
margin: 7% auto;
 text-align: center;
 padding: 12px;
 border-radius: 10px;
 text-decoration: none; 
 -webkit-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
 -moz-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
 box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
 /* margin: 0 auto 15% 0; */
 animation: anim 7s linear infinite;
}

.hero__btn:hover{
  padding: 20px ;
  background: rgb(44, 44, 46);

}

.main {
 border-radius: 0px;
  position: relative;
  z-index: 3;
 overflow: hidden;
}


.main section {
  width: 70%;
  padding: 90px 0px 0px 0px;
  margin: 50px auto 150px auto;
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  border-radius: 7px;

}



.main section h2 {
  font-size: 4.0rem;
  color: #ddd;
  text-transform: uppercase;
 line-height: 4.2rem; 
 padding-right: 10px;
 border-right: 3px solid #ddd;
}



.main section div {
  position: relative;

}

.main section div p {
  padding-left: 10px;
}

.gallery{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 820px;
  width: 95%;
  margin: 0px auto 0px auto;
  -webkit-box-shadow: 0px 10px 20px -11px rgba(0,0,0,0.9);
 -moz-box-shadow: 0px 10px 20px -11px rgba(0,0,0,0.9);
 box-shadow: 0px 10px 20px -11px rgba(0,0,0,0.9);
 /* background:; */
}

.gallery div {
  width: 100%;
  height: 100%;
  position: relative;
   overflow: hidden;
   filter: grayscale(83%);
}

/* images are not compressed, remember to compress them before deployment */


.radious {
  border: 4px white solid;
  border-style: ridge;
  border-radius: 7%;

}

.gallery div.gallery-item-one {
  background: url("https://loremflickr.com/750/300/house?random=1");
  background-size: 160%;
 grid-column-end: span 2;
 grid-row-end: span 2;


}

.gallery div.gallery-item-two {
  background: url("https://loremflickr.com/750/300/house?random=5");
  background-size: 160%;
  background-position: center;
background-size: cover;

}
.gallery div.gallery-item-three {
  background: url("https://loremflickr.com/750/300/house?random=2");
  background-size: 160%;
}

.gallery div.gallery-item-four {
  background: url("https://loremflickr.com/750/300/house?random=3");
  background-size: 160%;
}
.gallery div.gallery-item-five {
  background: url("https://loremflickr.com/750/300/house?random=4");
  background-size: 160%;
}
.gallery div.gallery-item-six {
  background: url("https://loremflickr.com/750/300/house?random=6");
  background-size: 160%;
}
.gallery div.gallery-item-one:hover, .gallery div.gallery-item-two:hover, .gallery div.gallery-item-three:hover, .gallery div.gallery-item-four:hover, .gallery div.gallery-item-five:hover, .gallery div.gallery-item-six:hover{
  background-size: 100%;
  filter: grayscale(0%);
  border: 7px blue solid;
  border-style: ridge;
  border-radius: 1%;
  padding: 50px;
}

 /*------------- SERVICES -------------------*/

div.services {
  display: grid;
  height: auto;
  grid-template-columns: repeat(3,1fr);
 padding: 10px;

}


.services div {
  text-align: center;
  background: #fff;
  width: 90%;
  border-radius: 10px;
  margin: 10px auto 50px auto;
  
  -webkit-box-shadow: 0px 10px 30px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 30px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 30px -9px rgba(0,0,0,0.75);
  
}

.services p.service-icon {
  font-size: 3.4rem;
  padding: 30px 0px 30px 90px;
  margin: 0px 0px 30px 0px;
  text-align: left;
  color: rgb(0, 99, 248);
  filter: grayscale(0%);
  border-bottom: 2px solid rgb(0, 99, 248);
}

.service-title{
  font-size: 2rem;
  padding: 0;
  margin: 0;
  text-decoration: none;
  /* background: rgba(62, 62, 65, 0.838); */
  display: block;
  color: #111;
}

.service-title:hover{
    background: #0D63F8;
    color:white;
}

.services p.service-title{
  font-size: 3.1rem;
  padding: 0;
  margin: 0;
}

.services p:last-of-type{
  padding: 0px 10px 20px 10px; 
  text-align: left;
}

.ourAgent__icon {
    font-size: 3.4rem;
  padding: 30px 0px 30px 90px;
  margin: 0px 0px 30px 0px;
  text-align: left;
  color: rgb(0, 248, 153);
  filter: grayscale(0%);
  border-bottom: 2px solid rgb(0, 248, 99);
}


 /*------------- FORM -------------------*/

.main section form {
  padding: 0px;
  margin: 0;
}
.main section h2 {
  font-size: 3rem;
}

.main section form div {
  border:1px solid #eee;
  padding: 10px;
border-radius: 10px;
font-size: 1.1rem;
display: grid;
margin-bottom: 10px;
grid-template-columns: 1fr 3fr;
align-items: center;

}

.main section form div:last-of-type {
  display: grid;
  grid-template-columns: 3fr 1fr;

} 

.main section form div:last-of-type input {
  background: rgb(0, 99, 248);
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
}

.main section form div label {
  background: #444;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  padding: 6px;
  text-align: center;
  margin-right: 10px;
  border-radius:10px;
  -webkit-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
}

.main section form div input {
  background: none;
  border: none;
  font-size: 1rem;
}
.main section form div textarea {
  font-family: Raleway;
 
  border: none;
  background: none;
  font-size: 1rem;
  resize: vertical;
}

.error {
  color: rgb(255, 57, 57);
  font-size: 1.3rem;
}



@media (max-width:1310px) and (min-width:1100px) {
    
    
    
    .main section {
      width: 90%;
      padding: 90px 0px 0px 0px;
      margin: 0px auto 50px auto;
      display: grid;
      grid-template-columns: 1fr;
      
    }
    .main section div p {
      padding: 0px 90px;
    }
    
    

    .services p.service-icon {
      font-size: 2.4rem;
      padding: 10px 30px;
      margin: 0px 0px 30px 0px;
      
    }
    .services p.service-title{
      font-size: 2.1rem;
      padding: 0;
      margin: 0;
    }
    
    .services p:last-of-type{
      padding: 0px 20px; 
      text-align: left;
    }

    .gallery{
      height: 600px;
    }
}



@media (min-width:850px) {
  


  .main section {
    width: 90%;
    padding: 90px 0px 0px 0px;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  .main section div p {
    padding: 0px 90px;
  }
  
  
  .services p.service-icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
    
  }
  .services p.service-title{
    font-size: 2.1rem;
    padding: 0;
    margin: 0;
  }
  
  .services p:last-of-type{
    padding: 0px 20px; 
    text-align: left;
  }

  .gallery{
    height: 420px;
  }
  
  .heroh2 {

    letter-spacing: 7px;
  /* margin-bottom: -114px; */
  background: white;
  position: relative;
  /* padding: 17px; */
  animation: text 3s 1;
  
  }
  @keyframes text {
    0% {
      /* color: black; */
      margin-bottom: -114px;
    }
    30%{
      letter-spacing: 25px;
      margin-bottom: -114px;
    }
    85%{
      letter-spacing: 7px;
      margin-bottom: -114px;
    }
  }
}


@media (max-width:849px) and (min-width:600px) {
 
  /* .link__ul {
display: flex;
flex: 1;
align-items: right;
text-align: right;
justify-content: flex-end;
flex-direction: column;
list-style: none;
} */

  .main section {
    width: 90%;
    padding: 90px 0px 0px  0px;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  
  .main section div p {
    padding: 0px 90px;
  }
  
 div.services {
   display: grid;
   grid-template-columns: 1fr;
  }
   div.services div {
    width: 70%;
  }

  .services p.service-icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
    
  }
  .services p.service-title{
    font-size: 2.1rem;
    padding: 0;
    margin: 0;
  }
  
  .services p:last-of-type{
    padding: 0px 20px; 
    text-align: left;
  }

  .gallery{
    height: 400px;
  }
  .heroh2 {

    letter-spacing: 7px;
  /* margin-bottom: -114px; */
  background: white;
  position: relative;
  /* padding: 17px; */
  animation: text 3s 1;
  
  }
  @keyframes text {
    0% {
      /* color: black; */
      margin-bottom: -114px;
    }
    30%{
      letter-spacing: 25px;
      margin-bottom: -114px;
    }
    85%{
      letter-spacing: 7px;
      margin-bottom: -114px;
    }
  }
}


@media (max-width:599px) and (min-width:0px) {
  
.hero div.head {
  /* position: relative;
  z-index: 2;
  display: grid; */
  width: 100%;
  /* grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  padding-top: 12%;
  align-items: center; */

}


 .main section {
    width: 90%;
    padding: 10px 0;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }

  

  .main section div p {
    padding: 0px 10px;
  }
  
  div.services {
   display: grid;
   grid-template-columns: 1fr;
  }
  div.services div {
    width: 80%;
  }

  .services p.service-icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
    
  }
  .services p.service-title{
    font-size: 2.1rem;
    padding: 0;
    margin: 0;
  }
  
  .services p:last-of-type{
    padding: 0px 20px; 
    text-align: left;
  }

  .gallery{
    grid-template-columns: 1fr;
    height: auto;
    margin: 30px auto 0px auto;
  }
  .gallery div {
    height: 200px;
  }

  .gallery div.gallery-item-one {
   grid-column-end: span 1;
   grid-row-end: span 1;
  }

 
  .main section form div label {
    margin-right:10px;
    border-radius: 5px;
  
  }
  
  .main section form div:last-of-type input {
   
    padding: 10px;
    border-radius: 5px;
  
  }
  
  
}