main section form {
  padding: 0px;
  margin: 0;
}


/* main section form div {
  border:1px solid #eee;
  padding: 10px;
border-radius: 10px;
font-size: 1.1rem;
display: grid;
margin-bottom: 10px;
grid-template-columns: 1fr 3fr;
align-items: center;

}

main section form div:last-of-type {
  display: grid;
  grid-template-columns: 3fr 1fr;

} 

main section form div:last-of-type input {
  background: rgb(0, 99, 248);
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
}

main section form div label {
  background: #444;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  padding: 6px;
  text-align: center;
  margin-right: 10px;
  border-radius:10px;
  -webkit-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
}

main section form div input {
  background: none;
  border: none;
  font-size: 1rem;
}
main section form div textarea {
  font-family: Raleway;
 
  border: none;
  background: none;
  font-size: 1rem;
  resize: vertical;
}

.error {
  color: rgb(255, 57, 57);
  font-size: 1.3rem;
} */

/* .contactme__form__input{
 background: white;
} */