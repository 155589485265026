

.btn{
  /* border: 2px solid #7b8083; */
  background: none;
  /* padding: 10px 20px; */
  font-size: 20px;
  font-family: "montserrat";
  cursor: pointer;
  margin: 0 3px;
  transition: 0.8s;
  position: relative;
  overflow: hidden;
}
.btn1,.btn2{
  color: #2b3033;
}
.btn3,.btn4{
  color: #ffffff;
}
.btn1:hover,.btn2:hover{
  color: #fff;
}
.btn3:hover,.btn4:hover{
  color: #212121;
}
.btn::before{
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: #8c9093;
  z-index: -1;
  transition: 0.8s;
  
}
.btn1::before,.btn3::before{
  top: 0;
  border-radius: 4px;
}
.btn2::before,.btn4::before{
  bottom: 0;
  border-radius: 4px ;
}
.btn3::before,.btn4::before{
  height: 100%;
}
.btn1:hover::before,.btn2:hover::before{
  height: 100%;
}
.btn3:hover::before,.btn4:hover::before{
  height: 0%;
}


.header{
  height: 100px;
  /* background: #34495e; */
  padding: 0 20px;
  color: #fff;
}
.logo {
  /* line-height: 30px; */
  float: left;
  text-transform: uppercase;
  /* width:50px; */
}
.fa-ellipsis-h{
  color:grey;
}
.menu{
  float: right;
  line-height: 40px;
  /* color:black; */
}
.menu a{
  /* color: black; */
  /* text-transform: uppercase; */
  text-decoration: none;
  padding: 0 3px;
  transition: 0.4s;
}
.show-menu-btn,.hide-menu-btn{
  transition: 0.4s;
  font-size: 30px;
  cursor: pointer;
  display: none;
}
.show-menu-btn{
  float: right;
}
.show-menu-btn i{
  line-height: 80px;
}
/* .menu a:hover,
.show-menu-btn:hover,
.hide-menu-btn:hover{
  color: #3498db;
} */

#chk{
  position: absolute;
  visibility: hidden;
  z-index: -1111;
}



@media screen and (max-width:750px) {
  .show-menu-btn,.hide-menu-btn{
    display: block;
  }
  .menu{
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgb(63, 63, 63);
    right: -100%;
    top: 0;
    text-align: center;
    padding: 80px 0;
    line-height: normal;
    transition: 0.7s;
    z-index: 5;
    /* display: none; */
    /* line-height: 100px; */
    margin-block-start:0;
  }
  .menu a{
    display: block;
    padding: 20px;
  }
  .hide-menu-btn{
    position: absolute;
    top: 40px;
    right: 40px;
  }
  #chk:checked ~ .menu{
    right: 0;

    
  }

}





.Header {
  /* height: 30px; */
  display: flex;
  align-items: normal;
  justify-content: space-between;
  /* position: fixed; */
  
}

/* .Header h1 {
  margin: 0;
  text-align: right;
  align-items: right;
  height: 100%;
} */

.logo a {
  color: #444;
  text-decoration: none;
  
}
.App__header {

  /* letter-spacing: 7px; */
/* margin-bottom: -114px; */
/* background: white; */
/* position: relative; */
/* padding: 17px; */
animation: key 6s 1;
/* margin-bottom: -35px; */


}
@keyframes key {
  0% {
    /* color: black; */
    /* letter-spacing: 25px; */
    margin-bottom: -20px;
  }
  50%{
    /* letter-spacing: 25px; */
    margin-bottom: -20px;
  }
  85%{
    /* letter-spacing: 7px; */
    /* margin-bottom: -70px;   */
    
  }
}

.Header__tagline--wide {
  opacity: 0.35;
  margin-left: 10px;
}

.Header__tagline--narrow {
  opacity: 0.35;
  display: block;
  text-align: center;
  display: none;
  margin-top: 10px;
}

.Header__logged-in {
  display: flex;
  flex: 1;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
margin: 0;
}

.Header__not-logged-in {
  display: flex;
  flex: 1;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
    margin: 0;
}
.link__ul {
display: flex;
flex: 1;
align-items: right;
text-align: right;
justify-content: flex-end;
flex-direction: row;
list-style: none;
}

.link__ul :hover {
  background: rgb(189, 188, 193);
  color: white;
  padding: 5px 15px;
  border-radius: 7px;
  /* margin-left: 10px; */

}

 .link__ul li {
  background: rgb(222, 221, 221);
  color: white;
  padding: 5px 10px;
  border-radius: 7px;
  border:2px solid white;
  /* margin-left: 10px; */

}
.link__ul li:first-child{ 
display: none;
}

.link__ul li:nth-child(2) {
  display: none;
}

.link__ul li:last-child {
  background: rgb(189, 188, 193);
  color: white;
  /* padding: 5px 15px; */
  border-radius: 7px;
  border:2px black soild;


}
.link__ul li:first-child {
  /* background: rgb(189, 188, 193); */
  color: white;
  padding: 5px 15px;
  border-radius: 7px;
  border:2px black soild;
  /* margin-left: 10px; */

}


/* .link__ul :hover {
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 5px 15px;
  border-radius: 15px;
} */


@media screen and (min-width: 650px) {
  .Header {
    align-items: baseline;
  }

  .Header h1 {
    font-size: 22pt;
  }
  
 .link__ul {
   display: flex;
   flex: 1;
   align-items: right;
   text-align: right;
   justify-content: flex-end;
   flex-direction: row;
   list-style: none;
 }

.link__ul li:first-child{ 
display: flex;
}

.link__ul li:nth-child(2) {
  display: flex;
}

.Header {
  height: 50px;
  display: flex;
  align-items: normal;
  justify-content: space-between;
  /* position: fixed; */
  
}
}

@media screen and (max-width: 490px) {
  .Header__tagline--wide {
    display: none;
  }

  .Header__tagline--narrow {
    display: inherit;
  }
   .link__ul {
    display: flex;
     flex: 1;
    align-items: right;
    text-align: right;
    justify-content: flex-end;
    flex-direction: row;
    list-style: none;
  }
  
}

