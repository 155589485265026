.footer {
    padding: 20px 0;
    text-align: center;
    grid-template-columns: 1fr;

}

.footer ul li a {
    font-size: 1.8rem;

}
.footer {
  background: darkgray;
  /* color: #91a3df; */
  /* padding: 20px; */
  /* margin-top: 80px; */
    /* margin-bottom: 80px; */
  display: grid;
  /* grid-template-columns:1fr 1fr 1fr; */
  align-items: center;
  text-align: center;
   /* background-color: #fff; */
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
border-radius: 0px 0px 7px 7px;
 /* height: 50%; */
}

.footer ul {
  
  list-style: none;
  display: grid;
  padding: 0;
  margin: 0;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

.footer ul li a {
  color: #eee;
  color: black;
}

