@import url(https://fonts.googleapis.com/css?family=Lato:200, 400);
@import url(https://fonts.googleapis.com/css?family=Lustria);


.btn{
  /* border: 2px solid #7b8083; */
  background: none;
  /* padding: 10px 20px; */
  font-size: 20px;
  font-family: "montserrat";
  cursor: pointer;
  margin: 0 3px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  position: relative;
  overflow: hidden;
}
.btn1,.btn2{
  color: #2b3033;
}
.btn3,.btn4{
  color: #ffffff;
}
.btn1:hover,.btn2:hover{
  color: #fff;
}
.btn3:hover,.btn4:hover{
  color: #212121;
}
.btn::before{
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: #8c9093;
  z-index: -1;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  
}
.btn1::before,.btn3::before{
  top: 0;
  border-radius: 4px;
}
.btn2::before,.btn4::before{
  bottom: 0;
  border-radius: 4px ;
}
.btn3::before,.btn4::before{
  height: 100%;
}
.btn1:hover::before,.btn2:hover::before{
  height: 100%;
}
.btn3:hover::before,.btn4:hover::before{
  height: 0%;
}


.header{
  height: 100px;
  /* background: #34495e; */
  padding: 0 20px;
  color: #fff;
}
.logo {
  /* line-height: 30px; */
  float: left;
  text-transform: uppercase;
  /* width:50px; */
}
.fa-ellipsis-h{
  color:grey;
}
.menu{
  float: right;
  line-height: 40px;
  /* color:black; */
}
.menu a{
  /* color: black; */
  /* text-transform: uppercase; */
  text-decoration: none;
  padding: 0 3px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.show-menu-btn,.hide-menu-btn{
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 30px;
  cursor: pointer;
  display: none;
}
.show-menu-btn{
  float: right;
}
.show-menu-btn i{
  line-height: 80px;
}
/* .menu a:hover,
.show-menu-btn:hover,
.hide-menu-btn:hover{
  color: #3498db;
} */

#chk{
  position: absolute;
  visibility: hidden;
  z-index: -1111;
}



@media screen and (max-width:750px) {
  .show-menu-btn,.hide-menu-btn{
    display: block;
  }
  .menu{
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgb(63, 63, 63);
    right: -100%;
    top: 0;
    text-align: center;
    padding: 80px 0;
    line-height: normal;
    -webkit-transition: 0.7s;
    transition: 0.7s;
    z-index: 5;
    /* display: none; */
    /* line-height: 100px; */
    -webkit-margin-before:0;
            margin-block-start:0;
  }
  .menu a{
    display: block;
    padding: 20px;
  }
  .hide-menu-btn{
    position: absolute;
    top: 40px;
    right: 40px;
  }
  #chk:checked ~ .menu{
    right: 0;

    
  }

}





.Header {
  /* height: 30px; */
  display: flex;
  align-items: normal;
  justify-content: space-between;
  /* position: fixed; */
  
}

/* .Header h1 {
  margin: 0;
  text-align: right;
  align-items: right;
  height: 100%;
} */

.logo a {
  color: #444;
  text-decoration: none;
  
}
.App__header {

  /* letter-spacing: 7px; */
/* margin-bottom: -114px; */
/* background: white; */
/* position: relative; */
/* padding: 17px; */
-webkit-animation: key 6s 1;
        animation: key 6s 1;
/* margin-bottom: -35px; */


}
@-webkit-keyframes key {
  0% {
    /* color: black; */
    /* letter-spacing: 25px; */
    margin-bottom: -20px;
  }
  50%{
    /* letter-spacing: 25px; */
    margin-bottom: -20px;
  }
  85%{
    /* letter-spacing: 7px; */
    /* margin-bottom: -70px;   */
    
  }
}
@keyframes key {
  0% {
    /* color: black; */
    /* letter-spacing: 25px; */
    margin-bottom: -20px;
  }
  50%{
    /* letter-spacing: 25px; */
    margin-bottom: -20px;
  }
  85%{
    /* letter-spacing: 7px; */
    /* margin-bottom: -70px;   */
    
  }
}

.Header__tagline--wide {
  opacity: 0.35;
  margin-left: 10px;
}

.Header__tagline--narrow {
  opacity: 0.35;
  display: block;
  text-align: center;
  display: none;
  margin-top: 10px;
}

.Header__logged-in {
  display: flex;
  flex: 1 1;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
margin: 0;
}

.Header__not-logged-in {
  display: flex;
  flex: 1 1;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
    margin: 0;
}
.link__ul {
display: flex;
flex: 1 1;
align-items: right;
text-align: right;
justify-content: flex-end;
flex-direction: row;
list-style: none;
}

.link__ul :hover {
  background: rgb(189, 188, 193);
  color: white;
  padding: 5px 15px;
  border-radius: 7px;
  /* margin-left: 10px; */

}

 .link__ul li {
  background: rgb(222, 221, 221);
  color: white;
  padding: 5px 10px;
  border-radius: 7px;
  border:2px solid white;
  /* margin-left: 10px; */

}
.link__ul li:first-child{ 
display: none;
}

.link__ul li:nth-child(2) {
  display: none;
}

.link__ul li:last-child {
  background: rgb(189, 188, 193);
  color: white;
  /* padding: 5px 15px; */
  border-radius: 7px;
  border:2px black soild;


}
.link__ul li:first-child {
  /* background: rgb(189, 188, 193); */
  color: white;
  padding: 5px 15px;
  border-radius: 7px;
  border:2px black soild;
  /* margin-left: 10px; */

}


/* .link__ul :hover {
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 5px 15px;
  border-radius: 15px;
} */


@media screen and (min-width: 650px) {
  .Header {
    align-items: baseline;
  }

  .Header h1 {
    font-size: 22pt;
  }
  
 .link__ul {
   display: flex;
   flex: 1 1;
   align-items: right;
   text-align: right;
   justify-content: flex-end;
   flex-direction: row;
   list-style: none;
 }

.link__ul li:first-child{ 
display: flex;
}

.link__ul li:nth-child(2) {
  display: flex;
}

.Header {
  height: 50px;
  display: flex;
  align-items: normal;
  justify-content: space-between;
  /* position: fixed; */
  
}
}

@media screen and (max-width: 490px) {
  .Header__tagline--wide {
    display: none;
  }

  .Header__tagline--narrow {
    display: inherit;
  }
   .link__ul {
    display: flex;
     flex: 1 1;
    align-items: right;
    text-align: right;
    justify-content: flex-end;
    flex-direction: row;
    list-style: none;
  }
  
}


main section form {
  padding: 0px;
  margin: 0;
}


/* main section form div {
  border:1px solid #eee;
  padding: 10px;
border-radius: 10px;
font-size: 1.1rem;
display: grid;
margin-bottom: 10px;
grid-template-columns: 1fr 3fr;
align-items: center;

}

main section form div:last-of-type {
  display: grid;
  grid-template-columns: 3fr 1fr;

} 

main section form div:last-of-type input {
  background: rgb(0, 99, 248);
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
}

main section form div label {
  background: #444;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  padding: 6px;
  text-align: center;
  margin-right: 10px;
  border-radius:10px;
  -webkit-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
}

main section form div input {
  background: none;
  border: none;
  font-size: 1rem;
}
main section form div textarea {
  font-family: Raleway;
 
  border: none;
  background: none;
  font-size: 1rem;
  resize: vertical;
}

.error {
  color: rgb(255, 57, 57);
  font-size: 1.3rem;
} */

/* .contactme__form__input{
 background: white;
} */
body {
  margin: 0;
  padding: 0;
  font-family: Raleway;
  background: #eee;
  color: #111;

}
body * {
  -webkit-transition: all 0.300s ease-in-out;
  transition: all 0.300s ease-in-out;
  border-radius: 7px 7px 0px 0px;
}

.container { 
  /* background-color: #fff; */
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
}

.hero {
  background: url("http://www.cgarchitect.com/content/portfolioitems/2014/01/92409/architectural-visualization-luxury-house-2_large.jpg");
  /* opacity:0.7; */
  background-size: 100%;
  height: 700px;
  background-position: center;
  position: relative;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   border-radius: 7px 7px 0px 0px;
   height: 100%;  
/* overflow: hidden;    */
/* opacity: 1.0; */
-webkit-transition: background 1.5s linear;
transition: background 1.5s linear;
/* animation: anim 20s linear infinite; */
}
@-webkit-keyframes anim {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100%{
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes anim {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100%{
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.landingPageh2 {
  font-size: 2rem;
  font-weight: 1000;


}

.heroh2{
  font-size: 2rem;
  font-weight: 1000;
}



.hero section.head {
  position: relative;
  z-index: 2;
  display: grid;
  /* width: 70%; */
  /* grid-template-columns: repeat(2,1fr); */
  margin: 0 auto;
  padding-top: 10%;
  align-items: center;
  
}

.hero section.head  {
  color: #222;
/* background-color: rgb(241, 224, 224); */
/* Fallback color */
background-color: rgba(255, 255, 255, 0.582);
text-align: center;
/* Black w/opacity/see-through */
}


/* .hero div.head h1 {
  text-align: center;
  font-size: 5rem;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 0px;
  margin: 0;
  letter-spacing: 4px;
  border-right: 2px solid #111;
} */

.hero section.head p {
  /* padding-left: 30px; */
  color:rgb(106, 81, 49);
  font-size: 1.5rem;
  font-weight: 1000;
  /* text-align: center; */
}

.hero__btn_div {
  margin: 0px auto;
}

.hero__btn {
  
 background: rgba(62, 62, 65, 0.838);
 display: block;
 color: #fff;
 font-weight: bold;
 width: 40%;
margin: 7% auto;
 text-align: center;
 padding: 12px;
 border-radius: 10px;
 text-decoration: none;
 box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
 /* margin: 0 auto 15% 0; */
 -webkit-animation: anim 7s linear infinite;
         animation: anim 7s linear infinite;
}

.hero__btn:hover{
  padding: 20px ;
  background: rgb(44, 44, 46);

}

.main {
 border-radius: 0px;
  position: relative;
  z-index: 3;
 overflow: hidden;
}


.main section {
  width: 70%;
  padding: 90px 0px 0px 0px;
  margin: 50px auto 150px auto;
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  border-radius: 7px;

}



.main section h2 {
  font-size: 4.0rem;
  color: #ddd;
  text-transform: uppercase;
 line-height: 4.2rem; 
 padding-right: 10px;
 border-right: 3px solid #ddd;
}



.main section div {
  position: relative;

}

.main section div p {
  padding-left: 10px;
}

.gallery{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 820px;
  width: 95%;
  margin: 0px auto 0px auto;
 box-shadow: 0px 10px 20px -11px rgba(0,0,0,0.9);
 /* background:; */
}

.gallery div {
  width: 100%;
  height: 100%;
  position: relative;
   overflow: hidden;
   -webkit-filter: grayscale(83%);
           filter: grayscale(83%);
}

/* images are not compressed, remember to compress them before deployment */


.radious {
  border: 4px white solid;
  border-style: ridge;
  border-radius: 7%;

}

.gallery div.gallery-item-one {
  background: url("https://loremflickr.com/750/300/house?random=1");
  background-size: 160%;
 grid-column-end: span 2;
 grid-row-end: span 2;


}

.gallery div.gallery-item-two {
  background: url("https://loremflickr.com/750/300/house?random=5");
  background-size: 160%;
  background-position: center;
background-size: cover;

}
.gallery div.gallery-item-three {
  background: url("https://loremflickr.com/750/300/house?random=2");
  background-size: 160%;
}

.gallery div.gallery-item-four {
  background: url("https://loremflickr.com/750/300/house?random=3");
  background-size: 160%;
}
.gallery div.gallery-item-five {
  background: url("https://loremflickr.com/750/300/house?random=4");
  background-size: 160%;
}
.gallery div.gallery-item-six {
  background: url("https://loremflickr.com/750/300/house?random=6");
  background-size: 160%;
}
.gallery div.gallery-item-one:hover, .gallery div.gallery-item-two:hover, .gallery div.gallery-item-three:hover, .gallery div.gallery-item-four:hover, .gallery div.gallery-item-five:hover, .gallery div.gallery-item-six:hover{
  background-size: 100%;
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  border: 7px blue solid;
  border-style: ridge;
  border-radius: 1%;
  padding: 50px;
}

 /*------------- SERVICES -------------------*/

div.services {
  display: grid;
  height: auto;
  grid-template-columns: repeat(3,1fr);
 padding: 10px;

}


.services div {
  text-align: center;
  background: #fff;
  width: 90%;
  border-radius: 10px;
  margin: 10px auto 50px auto;
  box-shadow: 0px 10px 30px -9px rgba(0,0,0,0.75);
  
}

.services p.service-icon {
  font-size: 3.4rem;
  padding: 30px 0px 30px 90px;
  margin: 0px 0px 30px 0px;
  text-align: left;
  color: rgb(0, 99, 248);
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  border-bottom: 2px solid rgb(0, 99, 248);
}

.service-title{
  font-size: 2rem;
  padding: 0;
  margin: 0;
  text-decoration: none;
  /* background: rgba(62, 62, 65, 0.838); */
  display: block;
  color: #111;
}

.service-title:hover{
    background: #0D63F8;
    color:white;
}

.services p.service-title{
  font-size: 3.1rem;
  padding: 0;
  margin: 0;
}

.services p:last-of-type{
  padding: 0px 10px 20px 10px; 
  text-align: left;
}

.ourAgent__icon {
    font-size: 3.4rem;
  padding: 30px 0px 30px 90px;
  margin: 0px 0px 30px 0px;
  text-align: left;
  color: rgb(0, 248, 153);
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  border-bottom: 2px solid rgb(0, 248, 99);
}


 /*------------- FORM -------------------*/

.main section form {
  padding: 0px;
  margin: 0;
}
.main section h2 {
  font-size: 3rem;
}

.main section form div {
  border:1px solid #eee;
  padding: 10px;
border-radius: 10px;
font-size: 1.1rem;
display: grid;
margin-bottom: 10px;
grid-template-columns: 1fr 3fr;
align-items: center;

}

.main section form div:last-of-type {
  display: grid;
  grid-template-columns: 3fr 1fr;

} 

.main section form div:last-of-type input {
  background: rgb(0, 99, 248);
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
}

.main section form div label {
  background: #444;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  padding: 6px;
  text-align: center;
  margin-right: 10px;
  border-radius:10px;
  box-shadow: 0px 10px 10px -9px rgba(0,0,0,0.75);
}

.main section form div input {
  background: none;
  border: none;
  font-size: 1rem;
}
.main section form div textarea {
  font-family: Raleway;
 
  border: none;
  background: none;
  font-size: 1rem;
  resize: vertical;
}

.error {
  color: rgb(255, 57, 57);
  font-size: 1.3rem;
}



@media (max-width:1310px) and (min-width:1100px) {
    
    
    
    .main section {
      width: 90%;
      padding: 90px 0px 0px 0px;
      margin: 0px auto 50px auto;
      display: grid;
      grid-template-columns: 1fr;
      
    }
    .main section div p {
      padding: 0px 90px;
    }
    
    

    .services p.service-icon {
      font-size: 2.4rem;
      padding: 10px 30px;
      margin: 0px 0px 30px 0px;
      
    }
    .services p.service-title{
      font-size: 2.1rem;
      padding: 0;
      margin: 0;
    }
    
    .services p:last-of-type{
      padding: 0px 20px; 
      text-align: left;
    }

    .gallery{
      height: 600px;
    }
}



@media (min-width:850px) {
  


  .main section {
    width: 90%;
    padding: 90px 0px 0px 0px;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  .main section div p {
    padding: 0px 90px;
  }
  
  
  .services p.service-icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
    
  }
  .services p.service-title{
    font-size: 2.1rem;
    padding: 0;
    margin: 0;
  }
  
  .services p:last-of-type{
    padding: 0px 20px; 
    text-align: left;
  }

  .gallery{
    height: 420px;
  }
  
  .heroh2 {

    letter-spacing: 7px;
  /* margin-bottom: -114px; */
  background: white;
  position: relative;
  /* padding: 17px; */
  -webkit-animation: text 3s 1;
          animation: text 3s 1;
  
  }
  @-webkit-keyframes text {
    0% {
      /* color: black; */
      margin-bottom: -114px;
    }
    30%{
      letter-spacing: 25px;
      margin-bottom: -114px;
    }
    85%{
      letter-spacing: 7px;
      margin-bottom: -114px;
    }
  }
  @keyframes text {
    0% {
      /* color: black; */
      margin-bottom: -114px;
    }
    30%{
      letter-spacing: 25px;
      margin-bottom: -114px;
    }
    85%{
      letter-spacing: 7px;
      margin-bottom: -114px;
    }
  }
}


@media (max-width:849px) and (min-width:600px) {
 
  /* .link__ul {
display: flex;
flex: 1;
align-items: right;
text-align: right;
justify-content: flex-end;
flex-direction: column;
list-style: none;
} */

  .main section {
    width: 90%;
    padding: 90px 0px 0px  0px;
    margin: 0px auto 50px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }
  
  .main section div p {
    padding: 0px 90px;
  }
  
 div.services {
   display: grid;
   grid-template-columns: 1fr;
  }
   div.services div {
    width: 70%;
  }

  .services p.service-icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
    
  }
  .services p.service-title{
    font-size: 2.1rem;
    padding: 0;
    margin: 0;
  }
  
  .services p:last-of-type{
    padding: 0px 20px; 
    text-align: left;
  }

  .gallery{
    height: 400px;
  }
  .heroh2 {

    letter-spacing: 7px;
  /* margin-bottom: -114px; */
  background: white;
  position: relative;
  /* padding: 17px; */
  -webkit-animation: text 3s 1;
          animation: text 3s 1;
  
  }
  @-webkit-keyframes text {
    0% {
      /* color: black; */
      margin-bottom: -114px;
    }
    30%{
      letter-spacing: 25px;
      margin-bottom: -114px;
    }
    85%{
      letter-spacing: 7px;
      margin-bottom: -114px;
    }
  }
  @keyframes text {
    0% {
      /* color: black; */
      margin-bottom: -114px;
    }
    30%{
      letter-spacing: 25px;
      margin-bottom: -114px;
    }
    85%{
      letter-spacing: 7px;
      margin-bottom: -114px;
    }
  }
}


@media (max-width:599px) and (min-width:0px) {
  
.hero div.head {
  /* position: relative;
  z-index: 2;
  display: grid; */
  width: 100%;
  /* grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  padding-top: 12%;
  align-items: center; */

}


 .main section {
    width: 90%;
    padding: 10px 0;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr;
    
  }

  

  .main section div p {
    padding: 0px 10px;
  }
  
  div.services {
   display: grid;
   grid-template-columns: 1fr;
  }
  div.services div {
    width: 80%;
  }

  .services p.service-icon {
    font-size: 2.4rem;
    padding: 10px 30px;
    margin: 0px 0px 30px 0px;
    
  }
  .services p.service-title{
    font-size: 2.1rem;
    padding: 0;
    margin: 0;
  }
  
  .services p:last-of-type{
    padding: 0px 20px; 
    text-align: left;
  }

  .gallery{
    grid-template-columns: 1fr;
    height: auto;
    margin: 30px auto 0px auto;
  }
  .gallery div {
    height: 200px;
  }

  .gallery div.gallery-item-one {
   grid-column-end: span 1;
   grid-row-end: span 1;
  }

 
  .main section form div label {
    margin-right:10px;
    border-radius: 5px;
  
  }
  
  .main section form div:last-of-type input {
   
    padding: 10px;
    border-radius: 5px;
  
  }
  
  
}

.Hyph {
  margin-right: 5px;
  margin-left: 5px;
}

.Input,
.Textarea {
  border: 1px solid #ddd;
  display: block;
  font-size: 1.1em;
  margin-bottom: 10px;
  padding: 10px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
}

.Input::-webkit-input-placeholder,
.Textarea::-webkit-input-placeholder {
  color: #ccc;
}

.Input::-moz-placeholder,
.Textarea::-moz-placeholder {
  color: #ccc;
}

.Input::-ms-input-placeholder,
.Textarea::-ms-input-placeholder {
  color: #ccc;
}

.Input::placeholder,
.Textarea::placeholder {
  color: #ccc;
}

.Textarea {
  resize: vertical;
}

.Button {
  order: 1px solid #aaa;
  background-color: #eee;
  font-size: 1.1em;
  border-radius: 4px;
  padding: 8px 12px;
  font-weight: bold;
}

.Required {
  color: #CA1551;
  font-family: Arial;
  font-weight: bold;
}

.Section {
  margin-left: 20px;
  margin-right: 20px;
}
.Section--list {
  margin-left: 10px;
  margin-right: 10px;
}

.PropertyListItem {
  background: none;
  display: flex;
  flex-direction: column;
  border: 0;
  font-size: smaller;
  text-decoration: none;
  box-shadow: 0px 11px 21px -8px rgba(0, 0, 0, 0.5);
  margin-bottom: 40px;
  /* margin-right: 20px; */
  border-radius: 50px 0px 50px 0px;
}

.PropertyListItem:hover {
  cursor: pointer;
}

.PropertyListItem,
.PropertyListItem:visited {
  color: #555;
}

.PropertyListItem__image {
  height: 250px;
  /* width: 275px; */
  border-radius: 50px 0px 0 0;
  background-size: cover;
  background-position: 50% 50%;
}

.PropertyListItem__details {
  padding: 10px;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  /* width: 275px; */
}

.PropertyListItem__details h2 {
  margin: 0;
}

.PropertyListItem__details .PropertyStarRating {
  padding: 5px 0;
  text-align: center;
  opacity: 0.85;
}

.PropertyListItem__details span {
  display: block;
  text-align: center;
  opacity: 0.5;
}

.PropertyListPage {
  border-radius: 50px 0px 50px 0px;
  /* display: flex; */
  /* justify-content: space-evenly; */
  /* flex-wrap: wrap; */
}

.ReviewForm select, .ReviewForm label {
  font-size: 1.1em;
}

.ReviewForm label {
  display: inline-block;
  margin: 10px 10px 20px;
  margin-left: 0;
}

.PropertyPage__image {
  height: 20vw;
  min-height: 150px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 10px 10px 0 0;
}

.PropertyPage__review-list {
  /* padding-inline-start: 20px; */
  list-style-type: none;
  padding: 0;
}

.PropertyPage__content {
  margin-bottom: 30px;
}

.PropertyPage__review {
  position: relative;
}

.PropertyPage__review::before {
  border-color: transparent #f5f5f5;
  border-style: solid;
  border-width: 0px 0px 10px 10px;
  content: '';
  display: block;
  height: 0px;
  position: absolute;
  width: 0;
  bottom: 14px;
  right: 7px;
}

.PropertyPage__review-icon {
  font-size: 12pt;
  margin-right: 10px;
  vertical-align: super !important;
}

.PropertyPage__review-text {
  background: #f5f5f5;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 4px;
}

.PropertyPage__review-user {
  color: #666;
  margin: 8px 0 0;
  font-size: 10pt;
  font-weight: bold;
  text-align: right;
}

.PropertyPage__review-user .PropertyStarRating {
  display: inline;
}

.LoginPage__header{
    /* padding-top: 70px; */
}
.RegistrationPage__header{
/* padding-top: 70px; */
}
.footer {
    padding: 20px 0;
    text-align: center;
    grid-template-columns: 1fr;

}

.footer ul li a {
    font-size: 1.8rem;

}
.footer {
  background: darkgray;
  /* color: #91a3df; */
  /* padding: 20px; */
  /* margin-top: 80px; */
    /* margin-bottom: 80px; */
  display: grid;
  /* grid-template-columns:1fr 1fr 1fr; */
  align-items: center;
  text-align: center;
   /* background-color: #fff; */
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
box-shadow: 0px 10px 54px -11px rgba(0,0,0,0.75);
border-radius: 0px 0px 7px 7px;
 /* height: 50%; */
}

.footer ul {
  
  list-style: none;
  display: grid;
  padding: 0;
  margin: 0;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

.footer ul li a {
  color: #eee;
  color: black;
}


.ListMyPropertyForm__submit {
    margin-bottom: 70px;
}
.ListMyPropertyPage__header {
    /* padding-top: 70px; */
}
.App {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 960px;
  margin: 0 auto;
}

.App__header {
  padding-left: 20px;
  padding-right: 20px;
}

.App__main {
  /* margin-top: 20px; */
}

.App__footer {
  /* margin-top: 30px; */
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smooproperty: antialiased;
  -moz-osx-font-smooproperty: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Lustria";
  font-weight: 100;
  color: #222;
}

p {
  color: #555;
}

.blue {
  color: #477ACC;
}

.yellow {
  color: #EAC435;
}

.green {
  color: #09a55e;
}

.red {
  color: #CA1551;
}

.orange {
  color: #FB4D3D;
}



